import cn from 'classnames';
import React, { ReactNode } from 'react';

interface LayoutProps {
  [key: string]: unknown;
  className?: string;
  children: ReactNode;
}

export function Layout(props: LayoutProps) {
  const { className, children } = props;

  return <div className={cn(className)}>{children}</div>;
}
